import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material";
import { BehaviorSubject } from "rxjs";

import { Operator } from "../../../shared/model/operator.model";
import { Resource } from "../../../shared/model/resource.model";
import { Vehicle } from "../../../shared/model/vehicle.model";
import { AlertService } from "../../../shared/services/alert.service";
import { CommentService } from "../../../shared/services/comment.service";
import { OperatorService } from "../../../shared/services/operator.service";
import { VehicleService } from "../../../shared/services/vehicle.service";

@Component({
  selector: "app-resource-detail",
  templateUrl: "./resource-detail.component.html",
  styleUrls: ["./resource-detail.component.scss"]
})
export class ResourceDetailComponent implements OnInit {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  _resource: Resource;
  hasUpdates: boolean = false;

  resourceForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ResourceDetailComponent>,
    private operatorService: OperatorService,
    private vehicleService: VehicleService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.createForm();
  }

  ngOnInit() {
    if (this.data) {
      this.resource = this.data.resource;
    }
    this.ngOnChanges();
  }

  get resource(): Resource {
    return this._resource;
  }

  set resource(resource: Resource) {
    this._resource = resource;
    this.ngOnChanges();
  }

  createForm() {
    let group = {
      start_date: [""],
      end_date: [""],
      note: [""]
    };

    this.resourceForm = this.fb.group(group);
  }

  ngOnChanges() {
    if (this.resourceForm) {
      this.resourceForm.reset();
      if (this.resource) {
        this.resourceForm.patchValue({
          start_date: this.resource.unavStartDate,
          end_date: this.resource.unavEndDate,
          note: this.resource.unavNote
        });
      }
    }
  }

  prepareSaveResource(): Resource {
    let saveResource: Resource = Resource.fromFormGroup(this.resourceForm);
    if (this.resource) {
      saveResource.objectId = this.resource.objectId;
    }

    return saveResource;
  }

  revert() {
    this.ngOnChanges();
  }

  close() {
    if (this.resourceForm.pristine) {
      this.doClose();
    } else {
      this.alertService
        .showConfirmDialog(
          "Chiudi",
          "Ci sono modifiche non salvate. Sei sicuro di voler chiudere?"
        )
        .subscribe(result => {
          if (result) {
            this.doClose();
          }
        });
    }
  }

  private doClose() {
    this.dialogRef.close({
      hasUpdates: this.hasUpdates,
      resource: this.resource
    });
  }

  onSubmit() {
    this.loadingSubject.next(true);
    let unsavedResource = this.prepareSaveResource();
    if (this.resource instanceof Operator) {
      this.operatorService.updateUnavailability(unsavedResource).subscribe(
        savedResource => {
          this.alertService.showConfirmMessage("Indisponibilità aggiornata");
          this.resource = savedResource;
          this.hasUpdates = true;
          this.loadingSubject.next(false);
          this.doClose();
        },
        error => {
          console.error(`Error while updating resource`, error);
          this.loadingSubject.next(false);
          this.doClose();
        }
      );
    } else if (this.resource instanceof Vehicle) {
      this.vehicleService.updateUnavailability(unsavedResource).subscribe(
        savedResource => {
          this.alertService.showConfirmMessage("Indisponibilità aggiornata");
          this.resource = savedResource;
          this.hasUpdates = true;
          this.loadingSubject.next(false);
          this.doClose();
        },
        error => {
          console.error(`Error while updating resource`, error);
          this.loadingSubject.next(false);
          this.doClose();
        }
      );
    }
  }
}
